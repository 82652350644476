import React from 'react';
import Banner from '../components/Banner';
import {  Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Home = () => {

  return (
    <div className='about-background'>
      <Banner title_start={"A Propos de"} title_end={"SouthCrag"}></Banner>
      <Container maxWidth="lg" disableGutters>
      
        <Typography  className="about-title" component="div">
            Pourquoi ce projet ?
        </Typography>
        <Typography  className="about-content" component="div">
          SouthCrag est un projet à but non lucratif. L'objectif est d'aider les gens à trouver des blocs dans les falaises du Sud de la France près de Montpellier.
          J'ai remarqué qu'il était vraiment difficile de trouver des informations sur les secteurs d'escalade ici.
          Il y a peu de guides, et ceux qui existent sont anciens et comportent de nombreuses omissions.
          De plus, il n'y avait pas de site web ou de base de données disponible.
          Par conséquent, j'ai décidé de créer ma propre base de données et de suivre ensuite les traces de Boolder (voir plus loin) en me lançant dans le développement d'une carte interactive.
        </Typography>
        <Typography className="about-title" component="div">
        Un bref aperçu de moi-même.
        </Typography>
        <Typography  className="about-content" component="div">

        <div>Je suis Nicolas, informaticien spécialisé en intelligence artificielle. En dehors de mes activités technologiques, je suis un passionné d'escalade ayant débuté mon parcours d'escalade dans la forêt de Fontainebleau.</div>
        <div>Actuellement résidant à Montpellier, dans le sud de la France, j'ai rencontré le défi de trouver des informations complètes sur l'escalade de bloc dans ma région locale.</div>
        </Typography>
        <Typography  className="about-title" component="div">
            Contact
        </Typography>
        <Typography  className="about-content" component="div">
            Pour toute questions:
            <Link to={"mailto:cotekeblo.contact@gmail.com"}> cotekeblo.contact@gmail.com</Link>
        </Typography>
        <Typography  className="about-title" component="div">
            Boolder
        </Typography>
        <Typography  className="about-content" component="div">  
        C'est un excellent site web créé par des grimpeurs de Fontainebleau, conçu pour aider les habitants à découvrir les spots d'escalade de bloc grâce à une carte de géolocalisation.

          <div>
            <Link to={"https://www.boolder.com/"}>Découvrez leur travail ici</Link>
          </div>
        </Typography>
      </Container>
    </div>
  );
};

export default Home;
